import { Menu, Tooltip } from 'antd';
import messages from 'components/MainNav/messages';
import { NavLink, NavLinkProps, RouteComponentProps, withRouter } from 'react-router-dom';
import utilsMessages from 'utils/messages';

import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Store } from 'types/schema';
import media from 'utils/mediaStyle';
import { viewPermissions } from 'utils/permissionMap';
import SVGIcon from '../SVGIcon/SVGIcon';
import DefaultAvatar from './DefaultAvatar.svg';
interface SideNavType extends RouteComponentProps {
  opened: boolean;
  store: any;
  onToggle: () => void;
  permissions: string[];
  isPenddingApproval: boolean;
  countPendingOrder: number;
  user: any;
}
const StoreContainer = styled.div``;
const AccountAvatar = styled.img`
  width: 36pt;
  height: 36pt;
  flex-shrink: 0;
  height: 36pt;
  border-radius: 8px;
  border: 1px solid #dce0e9;
`;
const StoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const StoreName = styled.div`
  font-weight: 500;
  font-size: 14px;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  > span {
    font-weight: 400;
  }
  ${media.lg`
    width: 140px;
  `};
`;

const SideNavIcon = styled(SVGIcon)<any>`
  width: 24pt;
  height: 24pt;
  transition: all 0.2s;
`;
const SideNavLabel = styled.span<any>`
  position: relative;
  span.notif {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #fe5f55;
  }
`;

const StyledLink = styled(NavLink)<any>`
  transition: all 0.3s ease-in;
  padding: 0pt 10pt;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  &.active,
  &:hover {
    background-color: rgba(66, 66, 66, 0.05);
    color: rgba(66, 66, 66, 1);
  }
  position: relative;
`;
type ChildNavLinkProps = { opened: boolean } & NavLinkProps;
const ChildNavLink = styled(({ opened, ...rest }: ChildNavLinkProps) => <NavLink {...rest} />)<ChildNavLinkProps>`
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
  align-items: center;
  padding: 10pt 10pt 10pt 14pt;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  height: 58px;
  &.active,
  &:hover {
    color: #2f904f;
    .icon {
      display: flex;
    }
  }
`;
const SideNavItemWrapper = styled.div`
  .ant-menu {
    background: unset !important;
    border-right: unset !important;
    .ant-menu-item {
      padding: 0 !important;
      margin: 0;
      & > a {
        color: rgba(0, 0, 0, 0.54) !important;
      }
      &.ant-menu-item-selected {
        background-color: unset;
        color: unset;
      }
      &:after {
        border-right: unset !important;
      }
      &:hover {
        background-color: rgba(66, 66, 66, 0.05);
      }
    }
    .ant-menu-submenu-title {
      margin: 0 !important;
      padding: 0 10pt !important;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.54);
      &:active {
        background: unset !important;
      }
      &:hover {
        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
          background: linear-gradient(to right, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)) !important;
        }
      }
    }
    &.ant-menu-sub {
      .ant-menu-item {
        &:hover {
          color: rgba(0, 0, 0, 0.54) !important;
        }
        a {
          padding: 0 14pt !important;
        }
      }
    }
  }
`;
const SideNavWrapper = styled.nav`
  white-space: nowrap;
  width: 230px !important;
  ${StoreContainer} {
    margin: 25px 12px 25px 0px;
    flex-direction: row;
  }
  ${AccountAvatar}, ${SideNavIcon} {
    margin-left: 0;
  }
  transform: translate3d(0, 0, 0);
  ${StoreContainer} {
    flex-direction: row;
  }
  ${AccountAvatar}, ${SideNavIcon} {
    margin-left: 0;
  }
  ${ChildNavLink} {
    display: flex;
  }
`;
const SideNavItem = (props: any) => {
  const { children, favorite, ...rest } = props;
  return (
    <StyledLink favorite={favorite ? 'true' : 'false'} {...rest} exact={true}>
      {props.children}
    </StyledLink>
  );
};
const SideNavItemWithChildren = styled.div<any>`
  white-space: nowrap;
  &,
  &:visited {
    border-left-color: transparent;
    color: rgba(0, 0, 0, 0.54);
  }
  a {
    background-color: ${(props) => props.isActive && 'rgba(66, 66, 66, 0.05)'};
    color: ${(props) => props.isActive && 'rgba(66, 66, 66, 1)'};
  }
`;

const SideNavContainer = styled.div`
  position: sticky;
  top: 141px;
  height: fit-content;
  padding-left: 10pt;
  margin-bottom: 16px;
`;
interface IStoreProps {
  loggedIn: boolean;
  store: Store;
  opened: boolean;
  user: any;
}
const Store: FC<IStoreProps> = (props) => {
  const { user } = props;
  const alternativeImage = (name) => `https://ui-avatars.com/api/?name=${name}&size=512`;
  const avatarSource = user.imageUrl || alternativeImage(user.name) || DefaultAvatar;
  return (
    <StoreContainer>
      <StoreWrapper>
        <AccountAvatar src={avatarSource} />
        <Tooltip
          title={
            localStorage.getItem('lang') === 'en' ? (
              <>
                {user.name}
                <FormattedMessage {...messages.ownerAccount} />
              </>
            ) : (
              <>
                <FormattedMessage {...messages.ownerAccount} />
                {user.name}
              </>
            )
          }
          overlayClassName="tooltip"
        >
          <StoreName>
            {localStorage.getItem('lang') === 'en' ? (
              <>
                {user.name}
                <FormattedMessage {...messages.ownerAccount} />
              </>
            ) : (
              <>
                <FormattedMessage {...messages.ownerAccount} />
                {user.name}
              </>
            )}
          </StoreName>
        </Tooltip>
      </StoreWrapper>
    </StoreContainer>
  );
};

const { SubMenu } = Menu;
const SideNav: FC<SideNavType> = (props) => {
  const { opened, store, location, permissions: userPermissions, user, isPenddingApproval } = props;

  const canAccessView = (path: string): boolean => {
    const permissions: string[] = viewPermissions[path];
    if (permissions) {
      let permissionCounter = 0;
      for (const permission of permissions) {
        if (userPermissions.indexOf(permission) > -1) {
          permissionCounter += 1;
        }
      }
      if (permissionCounter !== permissions.length) {
        return false;
      }
    }
    return true;
  };
  const path = location.pathname;
  return (
    <SideNavContainer>
      <SideNavWrapper className={opened ? 'opened' : ''}>
        <Store opened={opened} store={store} loggedIn={true} user={user} />
        <SideNavItemWrapper>
          <Menu mode="inline" defaultOpenKeys={['organization', 'report', 'order']}>
            <Menu.Item>
              <SideNavItem to="/account-info">
                <SideNavLabel showing={opened}>
                  <FormattedMessage {...messages.accountSetting} />
                </SideNavLabel>
              </SideNavItem>
            </Menu.Item>
            <SubMenu
              key="order"
              title={
                <span>
                  <FormattedMessage {...messages.orders} />
                </span>
              }
            >
              <Menu.Item>
                <SideNavItemWithChildren isActive={['/orders', '/pending-orders-history'].includes(path)}>
                  <SideNavItem to="/orders">
                    <SideNavLabel showing={opened}>
                      <FormattedMessage {...messages.orderHistory} />
                    </SideNavLabel>
                  </SideNavItem>
                </SideNavItemWithChildren>
              </Menu.Item>
              {isPenddingApproval && canAccessView('/orders-pending-approval') && (
                <Menu.Item>
                  <SideNavItem to="/orders-pending-approval">
                    <SideNavLabel showing={opened}>
                      <FormattedMessage {...messages.pendingApproval} />
                    </SideNavLabel>
                  </SideNavItem>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key="report"
              title={
                <span>
                  <FormattedMessage {...messages.report} />
                </span>
              }
            >
              <Menu.Item>
                <SideNavItem to="/overview">
                  <SideNavLabel showing={opened}>
                    <FormattedMessage {...messages.overviewReport} />
                  </SideNavLabel>
                </SideNavItem>
              </Menu.Item>
              <Menu.Item>
                <SideNavItem to="/item-analysis">
                  <SideNavLabel showing={opened}>
                    <FormattedMessage {...messages.itemsAnalysis} />
                  </SideNavLabel>
                </SideNavItem>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="organization"
              title={
                <span>
                  <FormattedMessage {...utilsMessages.businessSettings} />
                </span>
              }
            >
              <Menu.Item>
                <SideNavItem to="/company-info">
                  <SideNavLabel showing={opened}>
                    <FormattedMessage {...messages.companyInfo} />
                  </SideNavLabel>
                </SideNavItem>
              </Menu.Item>
              <Menu.Item>
                <SideNavItem to="/store-info">
                  <SideNavLabel showing={opened}>
                    <FormattedMessage {...messages.storeInfo} />
                  </SideNavLabel>
                </SideNavItem>
              </Menu.Item>
              {canAccessView('/user-management') && (
                <Menu.Item>
                  <SideNavItem to="/user-management">
                    <SideNavLabel showing={opened}>
                      <FormattedMessage {...messages.userManagement} />
                    </SideNavLabel>
                  </SideNavItem>
                </Menu.Item>
              )}
              {canAccessView('/user-management') && (
                <Menu.Item>
                  <SideNavItem to="/group-management">
                    <SideNavLabel showing={opened}>
                      <FormattedMessage {...messages.groupManagement} />
                    </SideNavLabel>
                  </SideNavItem>
                </Menu.Item>
              )}
            </SubMenu>
          </Menu>
        </SideNavItemWrapper>
      </SideNavWrapper>
    </SideNavContainer>
  );
};

export default withRouter(SideNav);
